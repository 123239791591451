import React from 'react';
import { graphql } from 'gatsby'
import NotFound from 'components/NotFound';
import Layout from 'components/Layout'

export default class NotFoundPage extends React.Component {
  render() {
    return <Layout location={this.props.location}>
      <NotFound heroImg={this.props.data.heroImg.childImageSharp.gatsbyImageData} />
    </Layout>;
  }
}

export const query = graphql`
  query NotFoundImageQuery {
    heroImg: file(relativePath: { eq: "zarolha-80.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

