import React from 'react';
import Link from 'gatsby-link';

import Hero from 'components/Hero';
import * as styles from './styles.module.scss';

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <Hero id={styles.hero} img={this.props.heroImg} darkenedEdges={true}>
          <div className={styles.content}>
            <div className={styles.name}>Oh no!</div>
            <p>You found a page that moved elsewhere or no longer exists</p>
            <Link to="/">Head back</Link>
          </div>
        </Hero>
      </div>
    );
  }
}
